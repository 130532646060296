import React from "react";

/* Images*/
import location from "../../assets/images/location.svg";
import phone from "../../assets/images/phone.svg";
import email from "../../assets/images/message.svg";

export default function Footer () {
    return (
        <footer className="brand-bg footer-wrapper flex-container-column">
            <ul className="footer-list flex-container-column">
                <li className="footer-list__item flex-container-centered">
                    <img src={location} alt="Location"/>
                    <address>Address: 15/3 Admiral Isakov Ave, Yerevan 0082</address>
                </li>
                <li className="footer-list__item flex-container-centered">
                    <img src={phone} alt="Phone"/>
                    <address> <a href="tel:+374 60 700 400"> Phone: +374 60 700 400</a></address>
                </li>
                <li className="footer-list__item flex-container-centered">
                    <div className="self-start">
                        <img src={email} alt="Email"/>
                    </div>
                    <div className="footer-list__item left-alignment">
                        <address>General enquiries: <a href="mailto: contact@Paydrom.co">contact@Paydrom.co</a></address>
                        <address className="top-space">Provider partnership: <a href="mailto: info@paydrom.co">info@paydrom.co</a></address>
                    </div>
                </li>
            </ul>
            <p className="copyright-info">Copyright 2024 DIGITAIN. All rights reserved</p>
        </footer>
    )
}
