import DashboardChart from '../components/Animations/DashboardChart';
import TransactionChart from '../components/Animations/TransactionChart';

/* Assets */
import analyticsReporting from '../assets/images/statistics.svg';
import psManagement from '../assets/images/creditCard.svg';
import management from '../assets/images/manageWithdrawalTransaction.svg';
import pfManagement from '../assets/images/configuratiosGears.svg';
import riskManagement from '../assets/images/riskManagement.svg';
import apcoPay from '../assets/images/payment_logos/apcopay.svg';
import astroPay from '../assets/images/payment_logos/astroPay.svg';
import bitCashier from '../assets/images/payment_logos/bitcashier.svg';
import muchBetter from '../assets/images/payment_logos/muchBetter.svg';
import nuvei from '../assets/images/payment_logos/nuvei.svg';
import okto from '../assets/images/payment_logos/okto.svg';
import payFun from '../assets/images/payment_logos/pay-fun.svg';
import payBrokers from '../assets/images/payment_logos/paybrokers.svg';
import paySafe from '../assets/images/payment_logos/paysafe.svg';
import gigadat from '../assets/images/payment_logos/gigadat.svg';
import zimpler from '../assets/images/payment_logos/zimpler.svg';
import cashier from '../assets/images/cashier.svg';

/* HERO SLIDER */
import cashierGifFirst from '../assets/images/cashierGif1.gif';
import cashierGifSecond from '../assets/images/cashierGif2.gif';

/**
 * Constant values that commonly used in project
 */

export const SCROLL = {
    Y_OFFSET: 150
};

export const SLIDER_IMAGE_LIST = [
    nuvei,
    bitCashier,
    okto,
    payBrokers,
    payFun,
    apcoPay,
    astroPay,
    paySafe,
    gigadat,
    muchBetter,
    zimpler,
    nuvei,
    bitCashier,
    okto,
    payBrokers,
    payFun,
    apcoPay,
    astroPay,
    paySafe,
    gigadat,
    muchBetter,
    zimpler,
];

export const SLIDER_TYPE = {
    COMPONENT_TYPE: 1,
    IMAGE_TYPE: 2,
    VIDEO_TYPE: 3
}
export const SLIDER_LIST = [
    {
        id: 0,
        imgSrc: DashboardChart,
        type: SLIDER_TYPE.COMPONENT_TYPE,
        title: 'Built to enhance your payment efficiency',
        description: 'Paydrom is a payment gateway and payment service facilitator with global coverage. We\n'
            + '                                have combined our extensive expertise in iGaming industry and Payment service and tailor\n'
            + '                                suited Paydrom to maximize your business growth.'
    },
    {
        id: 1,
        imgSrc: TransactionChart,
        type: SLIDER_TYPE.COMPONENT_TYPE,
        title: 'Take your Business to the next level',
        description: 'Secure and quality payment services are an important part of any iGaming operation, that is why we bring you Paydrom.',
        descriptionListItem1: 'Offer the best local payment options',
        descriptionListItem2: 'Maximize your acceptance rate',
        descriptionListItem3: 'Increase end user satisfaction',
        descriptionListItem4: 'Enhance your operation efficiency'
    },
    {
        id: 2,
        imgSrc: cashierGifFirst,
        type: SLIDER_TYPE.IMAGE_TYPE,
        title: 'Give Your Payers the payment experience they deserve',
        descriptionListItem1: 'Modern user experience and an aesthetically pleasing interface for your gaming website',
        descriptionListItem2: 'Enhance your payment performance and user engagement by using dynamic Cashier content, updated based on end user behavior, prioritize and offer most relevant payment options to your end users',
    },
    {
        id: 3,
        imgSrc: cashierGifSecond,
        type: SLIDER_TYPE.IMAGE_TYPE,
        title: 'Easy and quick customization and localization of cashier',
        descriptionListItem1: 'Easily Manage and customize your Cashier look and feel ',
        descriptionListItem2: 'Quickly update user interface elements, colors and styles and publish them to your website',
        descriptionListItem3: 'Enable and manage features from intuitive settings interface',
    },
];

export const CARD_LIST = [
    {
        id: 0,
        imgSrc: analyticsReporting,
        title: 'Analytics & Reporting',
        subTitle: 'Track and analyze your business performance',
        description: 'Visualized with easy-to-use charts and graphs, we empower you to make quick and precise decisions and follow up on your business performance.'
    },
    {
        id: 1,
        imgSrc: psManagement,
        title: 'Payment System Management',
        subTitle: 'Effectively manage payment services',
        description: 'An easy-to-use interface to configure your payment account details and store them securely. We support multiple merchant accounts, to diversify your transaction traffic.'
    },
    {
        id: 2,
        imgSrc: management,
        title: 'Transaction Management',
        subTitle: 'Enhanced transaction acceptance',
        description: 'In addition to manual operation tools, our “Auto reconciliation” service is designed to increase transaction acceptance and ensure delivery of IPNs to merchant platforms.'
    },
    {
        id: 3,
        imgSrc: riskManagement,
        title: 'Risk and Fraud Prevention',
        subTitle: 'Identify and manage risks and fraud cases',
        description: 'Protect your business by detecting fraud cases, block cards or fraudsters and detect geolocation changes. You can soon benefit from our ML based velocity checks.'
    },
    {
        id: 4,
        imgSrc: cashier,
        title: 'Cashier',
        subTitle: 'Modern and flexible cashier interface for seamless payments',
        description: 'Give your payers the payment experience they deserve with a modern and an aesthetically pleasing Cashier interface for your gaming website Enhance your payment performance and user engagement by using dynamic cashier content, updated based on end user behavior.'
    },
    {
        id: 5,
        imgSrc: pfManagement,
        title: 'Cashier Management',
        subTitle: 'Easy and quick customization and localization of cashier',
        description: 'An easily to use builder tool to manage and customize your Cashier look and feel to quickly update user interface elements, colors and styles and publish them to your website. Integrated with Paydrom’s payment account management tools providing possibility to control the payment accounts used by payers. You can manage risks and fraud cases and meet regulatory needs.'
    }
];

export const PAYMENT_SYSTEM_TYPE = [
    {value: 'mobilePayment', label: 'Mobile payment', id: '1'},
    {value: 'creditAndDebitCard', label: 'Credit & Debit Cards', id: '2'},
    {value: 'ewallet', label: 'E-wallet', id: '3'},
    {value: 'crypto', label: 'Crypto', id: '4'},
    {value: 'eVoucherAndPrepaid', label: 'E-Voucher/Prepaid', id: '5'},
    {value: 'bankTransfer', label: 'Bank Transfer', id: '6'},
    {value: 'cash', label: 'Cash', id: '7'}
];

export const USER_TYPE = [
    {
        value: 'gamingOperator',
        label: 'Gaming Operator'
    },
    {
        value: 'platformProvider',
        label: 'Platform Provider'
    },
    {
        value: 'paymentServiceProvider',
        label: 'Payment Service Provider'
    }
];

export const COUNT_CONFIGS = {
    start: 0,
    duration: 5,
    separator: ' ',
    prefix: '+'
};

export const COUNT_VALUES = [
    {
        end: 60,
        label: 'Providers',
        id: 1
    },
    {
        end: 600,
        label: 'Payment Options',
        id: 2
    },
    {
        end: 30,
        label: 'Countries',
        id: 3
    }
];
