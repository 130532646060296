import React, {useState} from "react";
import Select from "react-select";
import {getPaymentSystemByCountryOrPaymentsType} from "../../services/getPaymentSystemByCountryOrPaymentsType";
import {PAYMENT_SYSTEM_TYPE} from "../../constants/common";

const Portfolio = ({countriesAndPaymentsTypeList}) => {
    const countriesList = countriesAndPaymentsTypeList?.counrties;
    const [paymentList, setPaymentList] = useState([]);
    const [filterValue, setFilterValue] = useState({
        country: null,
        paymentsystemtype: null
    });

    const options = countriesList?.map((country) => ({
        value: country.toLowerCase(),
        label: country
    }));

    const onSelectChange = (option, attributes) => {
        const updatedFilter = {
            ...filterValue,
            [attributes.name]: option?.label
        };

        setFilterValue(updatedFilter);
        getPaymentSystemByCountryOrPaymentsType(setPaymentList, updatedFilter)
    };

    const handleResetValue = () => {
        setFilterValue({
            country: '',
            paymentsystemtype: ''
        });
        setPaymentList([])
    }

    return (
        <section id="ourPortfolio" className="our-portfolio-wrapper">
            <h2 className="section-title"> OUR PORTFOLIO </h2>
            <h4 className="section-subtitle">Discover our continuously growing portfolio</h4>
            <div className="flex-container-centered filter-wrapper">
                <Select
                    options={options}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    name="country"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder='By Country'
                    value={filterValue.country ? options.find((type) => type.value
                        === filterValue.country) : null}
                    onChange={onSelectChange}
                />
                <div className="flex-container-column select-btn-wrapper">
                    <Select
                        options={PAYMENT_SYSTEM_TYPE}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        name="paymentsystemtype"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="By Type"
                        value={filterValue.paymentsystemtype ? PAYMENT_SYSTEM_TYPE.find((type) => type.value
                            === filterValue.paymentsystemtype) : null}
                        onChange={onSelectChange}
                    />
                    {filterValue.country?.length || filterValue.paymentsystemtype?.length ? (
                        <button
                            type="submit"
                            className="reset-btn"
                            onClick={handleResetValue}>Reset</button>
                    ) : null}
                </div>
            </div>
            <ul className="portfolio-container payment-list flex-container-centered">
                {filterValue?.country || filterValue?.paymentsystemtype ? paymentList?.length ? paymentList?.map((paymentItem) => {
                    return (
                        <li className="payment-list__item flex-container-centered" key={paymentItem.id}>
                            <div className="payment-logo-wrapper">
                                <img
                                    src={paymentItem.imagePath}
                                    alt={paymentItem.paymentSystemName}
                                />
                            </div>
                        </li>
                    )
                }) : (
                    <p className="without-data">
                        We might not have this option yet but we continually expand our portfolio. Please check back for
                        updates.
                    </p>
                ) : (
                    <p className="without-data">
                        Get access to the best local payment options with 1 integration
                    </p>
                )}
            </ul>
        </section>
    )
}

export default Portfolio;
