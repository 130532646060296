import React, {useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from "swiper";
import {SLIDER_TYPE} from '../../constants/common'
import PropTypes from 'prop-types';
import 'swiper/css';


const HeroCarousel = function ({
       sliderList,
       toggleModal
   }) {
    return (
        <Swiper
            centeredSlides={true}
            autoplay={{
                delay: 17000,
                disableOnInteraction: false
            }}
            loop={true}
            modules={[Autoplay]}
            slidesPerView={1}
        >
            <>
                {sliderList.map((sliderItem) => {
                        const AnimationComponent = sliderItem.imgSrc;

                        return (
                            <SwiperSlide
                                key={sliderItem.id}>
                                {({isActive}) => {
                                    return (
                                        <figure className="flex-container space-between carousel-container">
                                            <figcaption className="hero-wrapper__content">
                                                <h2 className="hero-wrapper__title">
                                                    {sliderItem.title}
                                                </h2>
                                                <p className="hero-wrapper__description">
                                                    {sliderItem.description}
                                                </p>
                                                {sliderItem?.descriptionListItem1 ? (
                                                    <>
                                                        <p className="hero-wrapper__description hero-wrapper__description--small">
                                                            {sliderItem.descriptionListItem1}
                                                        </p>
                                                        <p className="hero-wrapper__description">
                                                            {sliderItem.descriptionListItem2}
                                                        </p>
                                                        <p className="hero-wrapper__description">
                                                            {sliderItem.descriptionListItem3}
                                                        </p>
                                                        <p className="hero-wrapper__description">
                                                            {sliderItem.descriptionListItem4}
                                                        </p>
                                                    </>
                                                ) : null}
                                                <button
                                                    onClick={toggleModal}
                                                    type="button"
                                                    className="hero-wrapper__btn flex-container-centered"
                                                >
                                                    Watch Video
                                                </button>
                                            </figcaption>
                                            <div className="carousel-image">
                                                {sliderItem.type === SLIDER_TYPE.COMPONENT_TYPE ? (
                                                    <AnimationComponent
                                                        key={sliderItem.id + Date.now()}
                                                        isActive={isActive}
                                                    />
                                                ) : SLIDER_TYPE.IMAGE_TYPE ? (
                                                    <div className="carousel-image__pic">
                                                        <img src={sliderItem.imgSrc} alt=" Slider Image"/>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </figure>
                                    )
                                }}
                            </SwiperSlide>
                        )
                    }
                )}
            </>
        </Swiper>
    );
};

HeroCarousel.propTypes = {
    sliderList: PropTypes.array.isRequired
};

export default HeroCarousel;
