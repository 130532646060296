import React from "react";
import close from '../../assets/images/close.svg'
import video from '../../assets/images/paydrom.mp4'

const Modal = ({isModalOpen}) => {
    const handleCloseModal = () => {
        isModalOpen(false)
    }
    return (
        <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="video-wrapper flex-container-centered">
                <video controls autoPlay title="Paydrom" className="modal-video">
                    <source src={video}/>
                </video>
            </div>
            <button onClick={handleCloseModal} className="close_btn">
                <img src={close} alt="Close"/>
            </button>
        </div>
    )
}

export default Modal;
