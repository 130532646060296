import React, {useEffect, useState} from "react";
import {getCountriesAndPaymentsTypeList} from "./services/countries";
import Container from "./container/Container";

function App() {
    const [countriesAndPaymentsTypeList, setCountriesAndPaymentsTypeList] = useState([]);

    useEffect(() => {
        getCountriesAndPaymentsTypeList(setCountriesAndPaymentsTypeList);
    }, []);

    return (
        <div className="App">
            <Container
                countriesAndPaymentsTypeList={countriesAndPaymentsTypeList}
            />
        </div>
    );
}

export default App;
