import Config from '../configs/mainConfig';
import instance from './apiService';

export const getPaymentSystemByCountryOrPaymentsType = (setPaymentList, updatedFilter) => {
  instance.get(Config.apiURLs.getPaymentSystemByCountryOrPaymentsType, {
    params: {
      country: updatedFilter.country,
      paymentsystemtype: updatedFilter.paymentsystemtype
    }
  })
    .then((response) => {
      setPaymentList(response.data);
    })
    .catch((error) => error);
};
