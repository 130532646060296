import React, {useEffect, useState, useLayoutEffect} from "react";
import CountUp from "react-countup";
import {
    CARD_LIST,
    COUNT_CONFIGS,
    COUNT_VALUES,
    SCROLL,
    SLIDER_IMAGE_LIST,
    SLIDER_LIST
} from "../constants/common";
import Card from "../components/Card/Card";
// import FeedbackForm from "../components/FeedbackForm/FeedbackForm";
import Portfolio from "../components/Portfolio/Portfolio";
import Modal from "../components/Modal/Modal";
import HeroCarousel from "../components/Carousel/HeroCarousel";
import Footer from "../components/Footer/Footer";

/*Assets*/
import paydromLogo from "../assets/images/paydrom.svg";
import toTop from "../assets/images/toTop.svg";


const Container = function ({countriesAndPaymentsTypeList}) {
    const [header, setHeader] = useState("header")
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }

            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const [width] = useWindowSize();

    console.log(width);

    /***
     * Scroll listener for header color change
     */
    const listenScrollEvent = () => {
        if (window.scrollY < SCROLL.Y_OFFSET) {
            return setHeader("header")
        } else if (window.scrollY > SCROLL.Y_OFFSET) {
            return setHeader("header--shadow")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
    }, []);

    /***
     *
     * Toggle Hamburger menu
     */
    const handleToggle = () => {
        setIsOpen(!isOpen)
    }

    /***
     *
     * Close mobile menu after list click
     */
    const closeMenu = () => {
        setIsOpen(false)
    }

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    useEffect(() => {
        if (width >= 1024) {
            setIsOpen(false)
        }
    }, [width])

    return (
        <>
            <header
                className={`${header} flex-container header-wrapper ${isOpen ? "header--showed" : "header--hidden"}`}>
                <figure className="logo-wrapper flex-container">
                    <a href="#hero" className="flex-container-centered" onClick={() => closeMenu()}>
                        <img src={paydromLogo} alt="Paydrom Logo"/>
                    </a>
                </figure>
                <button onClick={handleToggle} type="button" className={`${isOpen ? "close" : "open"} hamburger-menu`}>
                    <div/>
                    <div/>
                    <div/>
                </button>
                <nav>
                    <ul className={`flex-container header-list ${isOpen ? "header-list--showed" : "header-list--hidden"}`}>
                        <li className="header-list__item" onClick={() => closeMenu()}>
                            <a href="#productFeatures">Product Features</a>
                        </li>
                        <li className="header-list__item"
                            onClick={() => closeMenu()}
                        >
                            <a href="#ourPartners">Our Partners</a>
                        </li>
                        <li className="header-list__item"
                            onClick={() => closeMenu()}
                        >
                            <a href="#ourPortfolio">Our Portfolio</a>
                        </li>
                        {/*<li className="header-list__item"*/}
                        {/*    onClick={() => closeMenu()}*/}
                        {/*>*/}
                        {/*    <a href="#yourFeedback">Your Feedback</a>*/}
                        {/*</li>*/}
                        <li className="header-list__item header-list__item--btn flex-container-centered">
                            <a href="https://pygadmin.stgletspayments.com/dashboard" target='_blank'
                               onClick={() => closeMenu()}
                            >Log In</a>
                        </li>
                    </ul>
                </nav>
            </header>
            {!isOpen ? (
                <main>
                    {/* ========================= Hero Section Start =========================== */}
                    <section id="hero" className="hero-wrapper flex-container-column brand-bg">
                        <div className="carousel-wrapper">
                            <HeroCarousel
                                sliderList={SLIDER_LIST}
                                toggleModal={toggleModal}
                            />
                        </div>
                        <ul className="count-list flex-container">
                            {COUNT_VALUES.map((counter) => (
                                <li className="count-list__item flex-container-column" key={counter.id}>
                                <span className="main-count">
                                    <CountUp
                                        start={COUNT_CONFIGS.start}
                                        end={counter.end}
                                        duration={COUNT_CONFIGS.duration}
                                        separator={COUNT_CONFIGS.separator}
                                        prefix={COUNT_CONFIGS.prefix}
                                    />
                                </span>
                                    <span className="count-list__item-label">{counter.label}</span>
                                </li>
                            ))}
                        </ul>
                    </section>
                    {/* ========================= Hero Section End =========================== */}

                    {/* ========================= Product Features Section Start =========================== */}
                    <section id="productFeatures" className="product-features-wrapper">
                        <h1 className="section-title"> WHAT WE DO </h1>
                        <Card list={CARD_LIST}/>
                    </section>
                    {/* ========================= Product Features Section End =========================== */}

                    {/* ========================= Our Partners Section End =========================== */}
                    <section id="ourPartners" className="our-partners-wrapper">
                        <h2 className="section-title"> WE COOPERATE WITH </h2>
                        <div className="brand-bg partners-list flex-container-centered">
                            <div className="slider-container">
                                <div className="slider-content">
                                    {SLIDER_IMAGE_LIST.map((el) => (
                                        <div className="slider-content__item flex-container-centered">
                                            <img src={el} alt="Partners Logo"/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* ========================= Our Partners Section End =========================== */}

                    {/* ========================= Our Portfolio Section Start =========================== */}
                    <Portfolio
                        countriesAndPaymentsTypeList={countriesAndPaymentsTypeList}
                    />
                    {/* ========================= Our Portfolio Section End =========================== */}

                    {/* ========================= Feedback Section Start=========================== */}
                    {/*<FeedbackForm/>*/}
                    {/* ========================= Feedback Section End =========================== */}

                    <a className="to-top" href='#hero'>
                        <img src={toTop} alt="To Top"/>
                    </a>
                </main>
            ) : null}
            {isModalOpen && (
                <Modal
                    isModalOpen={setIsModalOpen}
                />
            )}
            <Footer/>
        </>
    )
}

export default Container;
