import Config from '../configs/mainConfig';
import instance from './apiService';

export const getCountriesAndPaymentsTypeList = (setCountriesAndPaymentsTypeList) => {
  instance.get(Config.apiURLs.getCountriesAndPaymentsTypes)
    .then((response) => {
      setCountriesAndPaymentsTypeList(response.data);
    })
    .catch((error) => error);
};
