import axios from 'axios';
import Config from '../configs/mainConfig';

const instance = axios.create({
  baseURL: Config.apiURLs.baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8'
  }
});

export default instance;
