/**
 * Configurable data in project
 *
 * @const {Object}
 */

const Config = {
  apiURLs: {
    baseURL: 'https://pygadminapi.stgletspayments.com/api/',
    getCountriesAndPaymentsTypes: 'Landing/getCountriesAndPaymentsTypes',
    getPaymentSystemByCountryOrPaymentsType: 'Landing/getPaymentOptions',
    getPaymentOptions: 'Landing/getPaymentOptions',
    leaveFeedback: 'Landing/leaveFeedback'
  }
};

export default Config;
