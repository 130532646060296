import React from "react";

function Card({list}) {
    return (
        <ul className="card-list">
            {list?.map((card) => (
                <li key={card.id} className="card-list__item">
                    <div className="flex-container-centered card-gap">
                        <img src={card.imgSrc} alt={card.title}/>
                        <h2 className="card-list__item-title"> {card.title} </h2>
                    </div>
                    <h5 className="card-list__item-subtitle">{card.subTitle}</h5>
                    <p className="card-list__item-description">{card.description}</p>
                    <div className="list-arrow" />
                </li>
            ))}
        </ul>
    )
}

export default Card;
